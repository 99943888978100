<template>
  <div>
    <div class="isweixin" v-if="isweixin">
      <div class="isweixin-box">
        <img src="../src/img/weixintips.png" alt="" />
      </div>
    </div>
    <div v-show="!isweixin">
      <div class="iosTip" v-show="showTips">
        <img src="../src/img/iostip.png" />
        <button class="downloadButton2" id="downloadButton2">
          <span class="word2">立即下载</span>
        </button>
      </div>
      <div v-show="!showTips" class="main">
        <div class="leftCircle1"></div>
        <div class="rightCircle1"></div>

        <div class="leftCircle2"></div>
        <div class="rightCircle2"></div>

        <div class="leftCircle3"></div>
        <div class="rightCircle3"></div>
        <div class="rightCircle4"></div>

        <div class="headFloat">
          <div class="dividingLine">
            <div class="logo">
              <img src="./img/qingsheng_logo@2x.png" />
            </div>
            <div class="title">
              <span class="word1">倾声</span>
              <span class="txt3">真实交友 聊天秒回</span>
            </div>
            <button
              class="downloadButton"
              id="downloadButton"
              @click="clickDownload"
            >
              <span class="word2">立即下载</span>
            </button>
          </div>
        </div>
        <div class="middleBeauty">
          <div class="plan">
            <img src="./img/ic_kpt@2x.png" />
          </div>

          <div class="icon1">
            <img src="./img/ic_zrrz@2x.png" />
          </div>
          <div class="icon2">
            <img src="./img/ic_spyh@2x.png" />
          </div>
          <div class="icon3">
            <img src="./img/ic_smlt@2x.png" />
          </div>
        </div>

        <div class="downloadButtonPlan">
          <button
            class="downloadButton"
            id="downloadButton1"
            @click="clickDownload"
          >
            <span class="word2">立即下载</span>
          </button>
        </div>

        <div class="copyrightCase">
          <div class="group2">
            <img src="./img/ic_bt2@2x.png" />
          </div>
        </div>

        <div class="beautyList">
          <div class="group" v-for="(i, key) in list" :key="i">
            <div class="avatar">
              <!--          <img src="./img/bg_1v1_1@2x.png" />-->
              <img :src="require('./img/bg_1v1_' + (key + 1) + '@2x.png')" />
              <div class="rightText">
                <span class="dian"></span>
                <span class="text">在线</span>
              </div>
            </div>
            <div class="name">
              <div class="nameText">{{ i.name }}</div>
              <div class="rightText">
                <span class="dian"></span>
                <span class="text">真人</span>
              </div>
            </div>
            <div class="desc">
              <div class="age">{{ i.age }}</div>
              <div style="display: block; float: right">
                <div class="dingwei"></div>
                <div class="address">{{ i.address }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserEnv } from "@/utils/common";

export default {
  name: "App",
  components: {},
  data: function () {
    return {
      userEnv: {},
      showTips: false,
      isweixin: false,
      list: [
        {
          name: "紫霞仙子",
          age: "26岁·164cm",
          address: "距你0.8km",
          PlaceImgUrl: "../img/bg_1v1_1@2x.png",
        },
        {
          name: "小阳子",
          age: "25岁·165cm",
          address: "距你0.59km",
          PlaceImgUrl: "./img/bg_1v1_1@2x.png",
        },
        {
          name: "假日温暖",
          age: "21岁·164cm",
          address: "距你0.8km",
          PlaceImgUrl: "./img/bg_1v1_1@2x.png",
        },
        {
          name: "慧慧一直在",
          age: "26岁·164cm",
          address: "距你1.24km",
          PlaceImgUrl: "./img/bg_1v1_1@2x.png",
        },
        {
          name: "欣宝",
          age: "20岁·163cm",
          address: "距你1.47km",
          PlaceImgUrl: "./img/bg_1v1_1@2x.png",
        },
        {
          name: "阳光落落",
          age: "22岁·163cm",
          address: "距你1.83km",
          PlaceImgUrl: "./img/bg_1v1_1@2x.png",
        },
        {
          name: "森森",
          age: "25岁·166cm",
          address: "距你2.39km",
          PlaceImgUrl: "./img/bg_1v1_1@2x.png",
        },
        {
          name: "小月亮",
          age: "22岁·163cm",
          address: "距你2.83km",
          PlaceImgUrl: "./img/bg_1v1_1@2x.png",
        },
        {
          name: "丁当",
          age: "21岁·162cm",
          address: "距你3.23km",
          PlaceImgUrl: "./img/bg_1v1_1@2x.png",
        },
        {
          name: "甜妹妹",
          desc: "温柔甜美型",
          age: "24岁·165cm",
          address: "距你3.3km",
          PlaceImgUrl: "./img/bg_1v1_1@2x.png",
        },
      ],
    };
  },
  created() {
    this.userEnv = getUserEnv();
  },
  mounted() {
    if (this.isWeixinBrowser()) {
      this.isweixin = true;
    }
  },
  methods: {
    clickDownload() {
      if (this.userEnv.isiOS) {
        this.showTips = true;
      }
    },
    isWeixinBrowser() {
      let ua = navigator.userAgent.toLowerCase();
      return /micromessenger/.test(ua) ? true : false;
    },
  },
};
</script>

<style lang="scss" src="./style/index.scss"></style>
